/* eslint-disable */
import dynamic from 'next/dynamic';


import GoogleTagManagerNoscript from '@magalu/mixer-modules/build/esm/novisual/GoogleTagManagerNoscript';
import AdsBanner from '@magalu/mixer-modules/build/esm/components/ecommerce/AdsBanner';
import Header from '@magalu/mixer-modules/build/esm/components/ecommerce/Header';
import Outdoor from '@magalu/mixer-modules/build/esm/components/ecommerce/Outdoor';
import Footer from '@magalu/mixer-modules/build/esm/components/ecommerce/Footer';
import Favicon from '@magalu/mixer-modules/build/esm/novisual/Favicon';
import Preconnect from '@magalu/mixer-modules/build/esm/novisual/Preconnect';
import Preload from '@magalu/mixer-modules/build/esm/novisual/Preload';
import Datadog from '@magalu/mixer-modules/build/esm/novisual/Datadog';
import GoogleTagManagerHead from '@magalu/mixer-modules/build/esm/novisual/GoogleTagManagerHead';
import Stewie from '@magalu/mixer-modules/build/esm/novisual/Stewie';
import AllIn from '@magalu/mixer-modules/build/esm/novisual/AllIn';
import Criteo from '@magalu/mixer-modules/build/esm/novisual/Criteo';
import TikTok from '@magalu/mixer-modules/build/esm/novisual/TikTok';
import SpotifyAdAnalytics from '@magalu/mixer-modules/build/esm/novisual/SpotifyAdAnalytics';
import Hotjar from '@magalu/mixer-modules/build/esm/novisual/Hotjar';
import Radware from '@magalu/mixer-modules/build/esm/novisual/Radware';
import RTBHouse from '@magalu/mixer-modules/build/esm/novisual/RTBHouse';
import Vwo from '@magalu/mixer-modules/build/esm/novisual/Vwo';
import Azion from '@magalu/mixer-modules/build/esm/novisual/Azion';

const pageModules = {
  GoogleTagManagerNoscript,
  AdsBanner,
  Header,
  Outdoor,
  Footer,
  Favicon,
  Preconnect,
  Preload,
  Datadog,
  GoogleTagManagerHead,
  Stewie,
  AllIn,
  Criteo,
  TikTok,
  SpotifyAdAnalytics,
  Hotjar,
  Radware,
  RTBHouse,
  Vwo,
  Azion,
  BackToTop: dynamic(() => import('@magalu/mixer-modules/build/esm/components/ecommerce/BackToTop'),
    { ssr: false }
  ),
  CloseableAdUnit: dynamic(() => import('@magalu/mixer-modules/build/esm/components/ecommerce/CloseableAdUnit'),
    { ssr: false }
  ),
  ZipCodeBar: dynamic(() => import('@magalu/mixer-modules/build/esm/components/ecommerce/ZipCodeBar'),
    { ssr: false }
  ),
  ZipCodeInput: dynamic(() => import('@magalu/mixer-modules/build/esm/components/ecommerce/ZipCodeInput'),
    { ssr: false }
  ),
  Loader: dynamic(() => import('@magalu/mixer-modules/build/esm/components/ecommerce/Loader'),
    { ssr: false }
  ),
};

export default pageModules;
