import { objectConvertAlias, objectRemoveMatchedKey } from '@magalu/mixer-utils';
import defaultGetServerSideProps from '../default/getServerSideProps';
import Page from '../default/page';

import modules from './pageModules';
import pageConfig from './pageConfig.json';

const { routeAlias = {}, ...config } = pageConfig;
const Notfound = props => <Page {...props} modules={modules} />;

export const getStaticProps = (ctx = {}) => {
  const resolvedUrl = ctx.resolvedUrl?.split('?')?.[0] || pageConfig.routePath;

  const query = objectRemoveMatchedKey(ctx.query || {}, routeAlias);
  const params = objectConvertAlias(ctx.params || {}, routeAlias);

  return defaultGetServerSideProps({ ...ctx, ...config, params, query, resolvedUrl });
};

export default Notfound;
